import ContentLoader from "react-content-loader";

type Props = {};

const SkeletonProductItem = (props: Props) => {
  const bgColor = "#fbfbfb";
  return (
    <>
      <div className="row">
        <div className="col">
          <ContentLoader
            speed={2}
            width={"100%"}
            height={260}
            // viewBox="0 0 100% 260"
            backgroundColor={bgColor}
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
            <rect x="0" y="210" rx="3" ry="3" width="100%" height="20" />
            <rect x="0" y="240" rx="3" ry="3" width="80%" height="10" />
          </ContentLoader>
        </div>
        <div className="col">
          <ContentLoader
            speed={2}
            width={"100%"}
            height={260}
            // viewBox="0 0 100% 260"
            backgroundColor={bgColor}
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
            <rect x="0" y="210" rx="3" ry="3" width="100%" height="20" />
            <rect x="0" y="240" rx="3" ry="3" width="80%" height="10" />
          </ContentLoader>
        </div>
        <div className="col d-none d-sm-inline">
          <ContentLoader
            speed={2}
            width={"100%"}
            height={260}
            // viewBox="0 0 100% 260"
            backgroundColor={bgColor}
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
            <rect x="0" y="210" rx="3" ry="3" width="100%" height="20" />
            <rect x="0" y="240" rx="3" ry="3" width="80%" height="10" />
          </ContentLoader>
        </div>
        <div className="col d-none d-sm-inline">
          <ContentLoader
            speed={2}
            width={"100%"}
            height={260}
            // viewBox="0 0 100% 260"
            backgroundColor={bgColor}
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
            <rect x="0" y="210" rx="3" ry="3" width="100%" height="20" />
            <rect x="0" y="240" rx="3" ry="3" width="80%" height="10" />
          </ContentLoader>
        </div>
        <div className="col d-none d-lg-inline">
          <ContentLoader
            speed={2}
            width={"100%"}
            height={260}
            // viewBox="0 0 100% 260"
            backgroundColor={bgColor}
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
            <rect x="0" y="210" rx="3" ry="3" width="100%" height="20" />
            <rect x="0" y="240" rx="3" ry="3" width="80%" height="10" />
          </ContentLoader>
        </div>
      </div>
    </>
  );
};

export default SkeletonProductItem;
