import { useState, useEffect } from "react";

export const isClient = () => typeof window !== "undefined";

export const isServer = () => typeof window === "undefined";

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
};

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};

export const randomPageInRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


