export const convertRobotstoBoolean = (input: string) => {
    let robotsDefault = {
        noIndex: false,
        noFollow: false
    }
    const datas = input
    // const datas = "NOINDEX,NOFOLLOW"
    var kw = datas?.split(',');
    if(kw?.length > 0){
        robotsDefault.noIndex = kw[0]?.toLowerCase() === "noindex" ? true : false,
        robotsDefault.noFollow =  kw[1]?.toLocaleLowerCase() === "nofollow" ? true : false
    }
    return robotsDefault
}

export const convertKeywordstoArray = (input: string) => {
    const words = input
    let keywords = {
        name: "keywords",
        content: words
    }
    return [keywords]

}