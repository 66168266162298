import RatingStars from "@components/features/product-detail/info/Ratings";
import { PRODUCT_NOT_FOUND_IMG } from "@const/products.const";
import { Product } from "@interfaces/dto/products.dto";
import PriceItem from "./PriceItem";
import Link from "next/link";

interface Props {
  product: Product;
  is_bestseller?: boolean;
  item_number?: number;
}

const GridItem = ({ product, is_bestseller, item_number }: Props) => {
  const {
    image,
    name,
    star_rating,
    reviews_count,
    special_price,
    price,
    url_key,
    saleable,
  } = product;
  return (
    <Link href={`/products/${url_key}`}>
      <a className="card">
        <img
          src={image ? image : PRODUCT_NOT_FOUND_IMG}
          className="card-img-top"
          alt={product.name}
        />
        <div className="card-body">
          {is_bestseller && (
            <>
              <div className="no">{item_number}</div>
            </>
          )}
          <h4 className="name">{name}</h4>
          <div className="product-rating">
            <RatingStars starCount={star_rating} />
            <span className="align-self-center">{reviews_count}</span>
          </div>
          <PriceItem
            saleAble={saleable}
            dataPrice={price}
            dataSpecialPrice={special_price}
          />
        </div>
      </a>
    </Link>
  );
};

export default GridItem;
