import { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { TIMER_FLASHSALE } from "@const/flashsale-timer.const";

export interface Clockresp {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

interface Props {
  timeType?: string;
  setTimeType?: (time: string) => void;
  flashsaleNow?: () => void;
}

const Clock = ({ timeType, setTimeType, flashsaleNow }: Props) => {
  const [countDownDateTime, setCountDownDatetime] = useState<
    Date | number | null
  >(null);

  useEffect(() => {
    //Now
    const now = new Date();

    //Today
    const today = TIMER_FLASHSALE;

    //Tomorrow
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(24, 0, 0, 0);

    if (now < tomorrow) {
      setCountDownDatetime(tomorrow);
    } else {
      setCountDownDatetime(today);
    }
  }, []);

  const CompleteCount = () => {
    return (
      <p className="countdown">
        <span>00</span>
        <span>00</span>
        <span>00</span>
      </p>
    );
  };
  const renderer = ({ hours, minutes, seconds, completed }: Clockresp) => {
    if (completed) {
      return <CompleteCount />;
    } else {
      // Convert hours to AM/PM format
      let displayedHours = hours % 12 || 12; // Convert 0 to 12
      // Format the displayed time with zero-padding
      let formattedHours = zeroPad(displayedHours);

      let am_pm = hours < 12 ? "PM" : "AM";
      if (timeType !== am_pm) {
        // console.log("changed to", am_pm);
        setTimeType?.(am_pm);
        flashsaleNow?.();
      }
      if (displayedHours === 12) {
        formattedHours = zeroPad(0);
      }
      return (
        <p className="countdown">
          <span>{zeroPad(formattedHours)}</span>
          {/* <span>{zeroPad(hours)}</span> */}
          <span>{zeroPad(minutes)}</span>
          <span>{zeroPad(seconds)}</span>
        </p>
      );
    }
  };
  return (
    <>
      {countDownDateTime && (
        <Countdown date={countDownDateTime} renderer={renderer} />
      )}
    </>
  );
};

export default Clock;
