interface Props {
  starCount: number;
}

const RatingStars = ({ starCount }: Props) => {
  const stars = Array.from(Array(5).keys());

  return (
    <ul className="rating my-auto">
      <li>
        {stars.map((star) => (
          <i
            key={star}
            className={`icon icon-star ${starCount >= star + 1 && "active"}`}
          />
        ))}
      </li>
    </ul>
  );
};

export default RatingStars;
