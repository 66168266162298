import { numberToMoneyString } from "@utils/numbers.util";
import { encryptPrice } from "@utils/encryptPrice.util";
import { useCountdown } from "@components/features/flashsale/Context";
import { FLASHSALE_SOON_CATEGORY_ID } from "@const/flashsale-category.const";

interface Props {
  saleAble: boolean;
  dataPrice: number;
  dataSpecialPrice: number | undefined;
}

const PriceItem = ({ saleAble, dataPrice, dataSpecialPrice }: Props) => {
  const { idFlashsale } = useCountdown();

  return (
    <h5 className="price">
      {saleAble ? (
        <>
          {idFlashsale === FLASHSALE_SOON_CATEGORY_ID ? (
            <span>
              ฿
              {encryptPrice(
                `${numberToMoneyString(dataSpecialPrice ?? dataPrice, 0)}`
              )}
              .-
            </span>
          ) : (
            <span>
              ฿{numberToMoneyString(dataSpecialPrice ?? dataPrice, 0)}.-
            </span>
          )}

          {dataSpecialPrice && (
            <del className="mx-1">฿{numberToMoneyString(dataPrice, 0)}.-</del>
          )}
        </>
      ) : (
        <>
          <span className="text-muted">สินค้าหมด</span>
        </>
      )}
    </h5>
  );
};

export default PriceItem;
