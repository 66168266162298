import { HomePromotionDialyItem } from "@interfaces/dto/home-promotion-daily.dto";
import Link from "next/link";
import Modal from "react-bootstrap/Modal";

type Props = {
  screenSize?: number;
  isShow: boolean;
  handleClose: () => void | undefined;
  dataImages?: HomePromotionDialyItem[];
  selectedImg?: string;
  isTransparent?: boolean;
};

const ImageModal = ({
  screenSize,
  isShow,
  handleClose,
  dataImages,
  selectedImg,
  isTransparent,
}: Props) => {
  return (
    <>
      <Modal
        size={screenSize && screenSize >= 1920 ? "lg" : undefined}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isShow}
        onHide={handleClose}
        className={`${isTransparent ? "is-transparent" : ""}`}
      >
        <Modal.Header className="fs-6 border-0" closeButton></Modal.Header>
        <Modal.Body>
          {dataImages && dataImages?.length > 0 ? (
            <>
              {dataImages.map((value, idx) => {
                return (
                  <Link key={idx} href={value.link}>
                    <img
                      className="d-block w-100"
                      src={value.image}
                      alt={value.title}
                    />
                  </Link>
                );
              })}
            </>
          ) : (
            <img className="d-block w-100" src={selectedImg} alt="" />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImageModal;
